import React, { Component } from "react";
import { navigate } from "gatsby";
import { Location } from "@reach/router";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";
import { PasswordForgetLink } from "../PasswordForget";

const INITIAL_STATE = {
  email: "",
  password: "",
  error: null,
};

// const ERROR_CODE_ACCOUNT_EXISTS =
//   "auth/account-exists-with-different-credential";

// const ERROR_MSG_ACCOUNT_EXISTS = `
//   An account with an E-Mail address to
//   this social account already exists. Try to login from
//   this account instead and associate your social accounts on
//   your personal account page.
// `;

class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    const { email, password } = this.state;

    this.props.firebase
      .doSignInWithEmailAndPassword(email.trim(), password)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        if (this.props.location.state.continueUrl) {
          navigate(this.props.location.state.continueUrl);
        } else {
          navigate(ROUTES.HOME);
        }
      })
      .catch((error) => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, password, error } = this.state;

    const isInvalid = password === "" || email === "";

    return (
      <form onSubmit={this.onSubmit}>
        <input
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="Email Address"
          style={{ width: "100%" }}
        />
        <input
          name="password"
          value={password}
          onChange={this.onChange}
          type="password"
          placeholder="Password"
          style={{ width: "100%" }}
        />
        <div style={{ textAlign: "center" }}>
          <PasswordForgetLink />
        </div>
        <button className="fancy gold" disabled={isInvalid} type="submit">
          <span>log</span>in
        </button>
        <button
          className="fancy black"
          onClick={() => navigate(ROUTES.SIGN_UP)}
          type="button"
        >
          <span>sign</span>up
        </button>

        {error && <p className="field-error">{error.message}</p>}
      </form>
    );
  }
}

const SignInForm = withFirebase((props) => {
  return (
    <Location>
      {({ location }) => <SignInFormBase {...props} location={location} />}
    </Location>
  );
});

export default SignInForm;
